var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"align-center mt-1"},[_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"label":_vm.$lang('shared.search'),"background-color":_vm.dark ? '#1E1E1E' : 'white',"append-icon":"search","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"label":_vm.$lang('shared.showOnlyMy'),"hide-details":""},model:{value:(_vm.showOnlyMy),callback:function ($$v) {_vm.showOnlyMy=$$v},expression:"showOnlyMy"}})],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.templates,"items-per-page":50,"footer-props":{
          itemsPerPageOptions: [25, 50, 100, -1],
        },"loading":_vm.isTemplatesLoading,"search":_vm.search,"custom-sort":_vm.customSort,"sort-by":"createdAt","sort-desc":true},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCreatedAtDate(item))+" ")]}},{key:"item.report",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getReportName(item))+" ")]}},{key:"item.reportMode",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getReportMode(item))+" ")]}},{key:"item.accessType",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$lang(("accessType." + (item.accessType.toLowerCase()))))+" ")]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"bottom":"","icon":"","x-small":""},on:{"click":function($event){return _vm.goToReport(item)}}},on),[_c('v-icon',[_vm._v(" mdi-play ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("template.actions.generate")))])]),(item.deletable)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"bottom":"","icon":"","x-small":""},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},on),[_c('v-icon',[_vm._v(" delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("template.actions.delete")))])]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }