




















































































import { Component, Vue } from "vue-property-decorator";

import { ReportTemplateModel } from "@/templates/models/TemplateModel";
import ReportUtil from "@/reports/utils/ReportUtil";
import { MULTI_APP, CurrentUserModel } from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";
import EncodeUtil from "@/shared/utils/EncodeUtil";

@Component
export default class ReportTemplatesView extends Vue {
  readonly headers = [
    {
      text: this.$lang("template.name"),
      value: "name",
      width: "20%",
    },
    {
      text: this.$lang("template.description"),
      value: "description",
    },
    {
      text: this.$lang("template.createdAt"),
      value: "createdAt",
      width: "12%",
    },
    {
      text: this.$lang("template.report"),
      value: "report",
      width: "13%",
    },
    {
      text: this.$lang("template.reportMode"),
      value: "reportMode",
      width: "13%",
      sortable: true,
    },
    {
      text: this.$lang("template.owner"),
      value: "ownerName",
      width: "10%",
    },
    {
      text: this.$lang("accessType.title"),
      value: "accessType",
      width: "10%",
    },
    {
      text: this.$lang("segment.actions.title"),
      value: "action",
      align: "end",
      width: "10%",
      sortable: false,
    },
  ];
  search = "";
  showOnlyMy = false;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get templates(): Array<ReportTemplateModel> {
    return this.$store.state.templateStore.reportTemplates.filter(
      (template: ReportTemplateModel) => {
        if (this.showOnlyMy && template.ownerId !== this.currentUser.username) {
          return false;
        }

        return this.applicationId === MULTI_APP
          ? ReportUtil.isMultiAppReport(template.report)
          : template.applicationIds.includes(this.applicationId);
      }
    );
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get isTemplatesLoading(): boolean {
    return this.$store.state.templateStore.isReportTemplatesLoading;
  }

  async goToReport(template: ReportTemplateModel) {
    const compressedQueryParams = await EncodeUtil.compressRouteQuery(
      JSON.stringify(template.getQuery())
    );

    if (template.applicationIds.length > 1) {
      this.$router.push({
        name: template.report,
        params: { id: MULTI_APP },
        query: {
          data: compressedQueryParams,
        },
      });
    } else {
      this.$router.push({
        name: template.report,
        params: {
          id: this.applicationId,
        },
        query: {
          data: compressedQueryParams,
        },
      });
    }
  }

  getReportName(item: ReportTemplateModel): string {
    return ReportUtil.getReportNameByReportType(item.report);
  }

  getReportMode(item: ReportTemplateModel): string {
    return item.applicationIds.length > 1
      ? this.$lang("template.multiapp")
      : this.$lang("template.singleapp");
  }

  getCreatedAtDate(item: ReportTemplateModel): string {
    return DateUtil.formatDate(item.createdAt);
  }

  showDeleteDialog(item: ReportTemplateModel) {
    this.$emit("delete", ReportTemplateModel.of(item));
  }

  customSort(
    items: Array<ReportTemplateModel>,
    [index]: Array<string>,
    [isDesc]: Array<boolean>
  ): Array<ReportTemplateModel> {
    items.sort((a: any, b: any) => {
      if (index === "reportMode") {
        if (!isDesc) {
          return b.applicationIds.length < a.applicationIds.length ? -1 : 1;
        } else {
          return a.applicationIds.length < b.applicationIds.length ? -1 : 1;
        }
      } else {
        if (!isDesc) {
          return a[index] < b[index] ? -1 : 1;
        } else {
          return b[index] < a[index] ? -1 : 1;
        }
      }
    });

    return items;
  }
}
